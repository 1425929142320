


export function vali_exist (rule, value, callback) {
  if (value && !value.trim()) {
    // eslint-disable-next-line standard/no-callback-literal
    callback('值不能空格')
  }
  callback()
}
